
import { computed, defineComponent, PropType } from 'vue';
import MetricBar from '@/components/MetricBarComponent.vue';
import { Event, GradedEvent } from '@/models/Event';
import { formatCategory, formatPhase } from '@/services/FormatService';
import { Student } from '@/models';

type EventViewModel = {
  unlocked: boolean;
  complete: boolean;
  eventName: string;
};

type EventCategory = {
  category: string;
  events: EventViewModel[];
};

export default defineComponent({
  components: { MetricBar },
  props: {
    student: {
      type: Object as PropType<Student>,
      required: true
    },
    phase: {
      type: String,
      required: true
    },
    phaseEvents: {
      type: Array as PropType<Array<Event>>,
      required: true
    },
    studentEvents: {
      type: Array as PropType<Array<GradedEvent>>,
      required: true
    },
    unlockedEvents: {
      type: Array as PropType<Array<Event>>,
      required: true
    }
  },
  emits: ['close', 'selectEvent'],
  setup(props, { emit }) {
    function handleCloseClick() {
      emit('close');
    }

    function handleEventNameClick(eventName: string) {
      emit('selectEvent', eventName);
    }

    function eventCompleted(event: Event): boolean {
      return (
        props.studentEvents.filter(
          (gradedEvent: GradedEvent) =>
            gradedEvent.name === event.name && gradedEvent.complete
        ).length > 0
      );
    }

    function eventUnlocked(event: Event): boolean {
      return props.unlockedEvents.findIndex((e) => e.name === event.name) > -1;
    }

    const eventCategories = computed(() => {
      const categoryNames = props.phaseEvents.reduce(
        (acc: string[], event: Event) => {
          if (!acc.includes(event.category)) {
            acc.push(event.category);
          }
          return acc;
        },
        []
      );

      return categoryNames.map((category: string) => {
        const events: EventViewModel[] = props.phaseEvents
          .filter((event) => event.category === category)
          .map((event) => {
            return {
              unlocked: eventUnlocked(event),
              complete: eventCompleted(event),
              eventName: event.name
            };
          });
        return {
          category,
          events
        };
      }) as EventCategory[];
    });

    const percentCompleted = computed(() => {
      const progress = props.student.getPhaseProgress();
      if (progress) {
        return Math.min(progress[props.phase].percentage, 100);
      } else {
        return 0;
      }
    });

    return {
      handleCloseClick,
      handleEventNameClick,
      eventCategories,
      formatCategory,
      formatPhase,
      percentCompleted
    };
  }
});
