
import { defineComponent, onMounted, ref } from 'vue';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default defineComponent({
  props: {
    percent: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const myDoughnutChart = ref(null);
    onMounted(() => {
      if (myDoughnutChart.value !== null) {
        const canvas: any = myDoughnutChart.value;
        if (!(canvas instanceof HTMLCanvasElement)) {
          return;
        }
        if (!canvas || !canvas.getContext) return;
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (!ctx) return;
        const remainder = props.percent - 100;
        new Chart(ctx, {
          type: 'doughnut',
          data: {
            datasets: [
              {
                data: [props.percent, remainder],
                backgroundColor: ['rgb(54, 162, 235)', 'rgba(0, 0, 0, 0.1)'],
                borderWidth: 0
              }
            ]
          },
          options: {
            cutout: '85%',
            plugins: {
              tooltip: {
                enabled: false
              }
            }
          }
        });
      }
    });
    return {
      myDoughnutChart
    };
  }
});
