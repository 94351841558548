
import { computed, defineComponent, PropType } from 'vue';
import StudentPhaseProgressOverview from '@/components/student/progression/StudentPhaseProgressOverview.vue';
import StudentPhaseProgressionCard from '@/components/student/progression/StudentPhaseProgressionCard.vue';
import StudentOverallProgression from '@/components/student/progression/StudentOverallProgression.vue';
import { useRoute, useRouter } from 'vue-router';
import Routes from '@/router/Routes';
import { Event, GradedEvent, Phase, Student } from '@/models';
import eventMetaData from '../../../cms-generated/eventMetaData.json';
import Class from '../../models/Class';
import { useStore } from 'vuex';

type EventPhase = {
  phase: Phase;
  events: Event[];
};

export default defineComponent({
  components: {
    StudentPhaseProgressionCard,
    StudentPhaseProgressOverview,
    StudentOverallProgression
  },
  props: {
    events: {
      type: Array as PropType<Array<Event>>,
      required: true
    },
    studentEvents: {
      type: Array as PropType<Array<GradedEvent>>,
      required: true
    },
    unlockedEvents: {
      type: Array as PropType<Array<Event>>,
      required: true
    },
    student: {
      type: Object as PropType<Student>,
      required: true
    },
    classData: {
      type: Object as PropType<Class>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    function handlePhaseClick(eventPhase: EventPhase) {
      router.push({
        name: Routes.STUDENT_PROGRESS,
        query: { phase: eventPhase.phase }
      });
    }

    function handleClosePhaseOverview() {
      router.push({
        name: Routes.STUDENT_PROGRESS
      });
    }

    async function handleEventSelection(eventName: string) {
      const currentClass = store.getters['classModule/currentClass'];
      await store.dispatch(
        'courseModule/fetchCurrentCourse',
        currentClass.courseId
      );
      router.push({
        name: Routes.SYLLABUS_EVENT_DETAILS,
        params: { eventName }
      });
    }

    const syllabusUrl = computed(() => {
      return props.classData.syllabusUrl as keyof typeof eventMetaData.syllabus;
    });

    const eventPhases = computed(() => {
      const syllabusMetaData = eventMetaData.syllabus[syllabusUrl.value];
      const phases = Object.keys(syllabusMetaData.phase);
      const allPhases = phases.map((phase: string) => {
        return {
          phase,
          events: props.events.filter((event) => event.phase === phase)
        };
      }) as EventPhase[];
      // TODO: SLP 756 Demo Phase Order hack - remove when fixed
      const orderedPhases = [
        ...allPhases.filter((phase) => phase.phase === 'preflight'),
        ...allPhases.filter((phase) => phase.phase === 'qualification'),
        ...allPhases.filter((phase) => phase.phase === 'mission'),
        ...allPhases.filter(
          (phase) =>
            phase.phase !== 'mission' &&
            phase.phase !== 'qualification' &&
            phase.phase !== 'preflight'
        )
      ];
      return orderedPhases;
    });

    const activeChapterPhase = computed(() => {
      return eventPhases.value.find((eventPhase) => {
        return eventPhase.phase === route.query.phase;
      });
    });

    const progress = computed(() => {
      const progress = props.student.getPhaseProgress();
      let overallProgressPercentage = 0;
      let keys = 0;
      for (const key in progress) {
        overallProgressPercentage += progress[key].percentage;
        keys++;
      }

      const returnValue = Math.min(
        Math.round(overallProgressPercentage / keys),
        100
      );
      const nanCheck = isNaN(returnValue);

      return nanCheck ? 0 : returnValue;
    });

    return {
      activeChapterPhase,
      eventPhases,
      handlePhaseClick,
      handleClosePhaseOverview,
      handleEventSelection,
      progress,
      route
    };
  }
});
