
import { computed, defineComponent, PropType } from 'vue';
import MetricBar from '@/components/MetricBarComponent.vue';
import { formatPhase } from '@/services/FormatService';
import { Student, Progress } from '@/models';

export default defineComponent({
  components: {
    MetricBar
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    phase: {
      type: String,
      required: true
    },
    student: {
      type: Object as PropType<Student>,
      required: true
    }
  },
  setup(props) {
    const percentComplete = computed(() => {
      const progress: Progress = props.student.getPhaseProgress();
      if (progress && progress[props.phase]) {
        const phaseProgress = progress[props.phase];
        //TODO: SLP-755 Demo Patch Progress Bars hack
        return Math.min(phaseProgress.percentage, 100);
      }
      return 0;
    });

    return {
      formatPhase,
      percentComplete
    };
  }
});
