
import { defineComponent } from 'vue';
import Icon from '@/components/icon/Icon';
import IconComponent from '@/components/icon/IconComponent.vue';
import DoughnutChart from '@/components/charts/DoughnutChart.vue';

export default defineComponent({
  components: { IconComponent, DoughnutChart },
  props: {
    percentComplete: {
      type: Number,
      required: true
    }
  },
  setup() {
    return { DoughnutChart, Icon };
  }
});
